import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Inject, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { envStateKeyModel } from '../../app.config.server';
import { MetricsService } from '../../service/MetricsService/MetricsService.service';

const ENV_KEY = makeStateKey<envStateKeyModel>('env');


@Component({
    selector: 'app-shared-event',
    imports: [],
    templateUrl: './shared-event.component.html',
    styleUrl: './shared-event.component.css'
})
export class SharedEventComponent implements OnInit{
  amplifymeUrl = "";
  imageUrl = "";
  metrics: any | null = null;
  referralCode: string | null = "";

  constructor(
    private route: ActivatedRoute,
    private meta: Meta,
    private metricsService: MetricsService,
    private transferstate: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const uid = params.get('uid')?.replace(/\/$/, '');

      if (uid) {
        const env = this.transferstate.get<envStateKeyModel>(ENV_KEY, {SHARE_URL:"", AMPLIFYME_URL: "", PATHWAYS_URL: "", HUBSPOT_TRACKING_CODE: ""});

        this.metricsService.getMetrics(env.SHARE_URL, uid).subscribe(metrics => {
          this.metrics = metrics;
          switch (this.metrics.event.subtype) {
            case 'Finance Accelerator': {
              this.referralCode = "ASPMFA"
              break;
            }
            case 'Sales & Trading': {
              this.referralCode = "ASPMST"
              break;
            }
            case 'Asset Manager': {
              this.referralCode = "ASPMAM"
              break;
            }
            case 'Hedge Fund': {
              this.referralCode = "ASPMHF"
              break;
            }
            case 'Equity Sector': {
              this.referralCode = "ASPMES"
              break;
            }
            case 'Quant Market Making':{
              this.referralCode = "ASPMMM"
              break;
            }
            case 'Global Macro':{
              this.referralCode = "ASPMGM"
              break;
            }
            case 'Mergers And Acquisition': {
              this.referralCode = "ASCBFA"
              break;
            }
            default: {
              this.referralCode = "ASPMDF"
              break;
            }
        }});

        // Construct the URL dynamically based on the env
        this.imageUrl = `${env.SHARE_URL}/images/${uid}.png`;
        this.amplifymeUrl = `${env.PATHWAYS_URL}/pathways?r=${this.referralCode}`;

        // Set dynamic meta tags with the constructed URL
        this.meta.updateTag({ property: 'og:image', content: this.imageUrl });

        // Get the current date and time in ISO format
        const currentTime = new Date().toISOString();
        this.meta.updateTag({ property: 'article:published_time', content: currentTime });
      }
    });
  }
}
