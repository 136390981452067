import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MetricsService {
  constructor(private http: HttpClient) { }

  getMetrics<T>(shareServiceUrl: string, uid: string): Observable<T | null> {
    const url = `${shareServiceUrl}/api/shares/${uid}`;
    
    return this.http.get<T>(url).pipe(
      catchError((error: HttpErrorResponse) => {
        // Error handling
        console.error('Error fetching Metrics', error);
        return of(null); // or any default value as needed
      })
    );
  }
}