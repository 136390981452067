<nav class="pb-4">
  <div class="bg-black font-montserrat">
    <div class="max-w-7xl px-4 lg:px-[160px] py-[20px]">
      <div class="relative flex h-[22px] items-center justify-between">
        <div class="flex flex-1 items-center justify-start">
          <div class="block">
            <div class="flex space-x-3">
              <div class="flex items-center">
                <img
                  src="https://lwfiles.mycourse.app/amplifyme-sandbox-public/svgs/2bb71255dce72fdbbdf5d81ce459cc8e.svg"
                  class="w-[19px]"
                />
                <a class="rounded-md pl-1 pr-6 text-xs md:text-sm text-white">
                  18 St Swithin’s Ln, London EC4N 8AD
                </a>
              </div>
              <div class="hidden md:flex items-center">
                <img
                  src="https://lwfiles.mycourse.app/amplifyme-sandbox-public/svgs/5eafea559ab3b18a45252f5b94574104.svg"
                  class="w-[19px]"
                />
                <a
                  href="tel:+44203372841"
                  class="rounded-md pl-1 pr-6 text-xs md:text-sm text-white hover:text-[#61A6FA]"
                  rel="nofollow"
                >
                  +44 (0) 203 372 8415
                </a>
              </div>
              <div class="flex items-center">
                <img
                  src="https://lwfiles.mycourse.app/amplifyme-sandbox-public/svgs/0e76d40f7c2c62927ab46748f6aac7e5.svg"
                  class="w-[19px]"
                />
                <a
                  href="mailto:info@amplifyme.com"
                  class="rounded-md pl-1 pr-6 text-xs md:text-sm text-white hover:text-[#61A6FA]"
                  rel="nofollow"
                >
                  info&#64;amplifyme.com
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        ></div>
      </div>
    </div>
  </div>
  <div class="bg-transparent">
    <div class="bg-black bg-opacity-30 mx-auto px-4 md:px-[120px]">
      <div class="relative flex h-[55px] items-center justify-between">
        <div class="flex flex-1 items-stretch justify-start">
          <div class="sm:block">
            <div class="flex">
              <div>
                <img
                  src="https://lwfiles.mycourse.app/amplifyme-sandbox-public/472ea43557890ef54c8989593b4c2f19.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        ></div>
      </div>
    </div>
  </div>
</nav>
<div class="flex flex-col items-center min-h-screen px-4">
  <div class="max-w-7xl">
    <img src="{{ imageUrl }}" class="mx-auto" />
  </div>
  <div class="mt-4 p-8">
    <a
      class="rounded-md bg-[#61A6FA] px-3.5 py-2.5 text-l md:text-2xl font-semibold text-white shadow-sm hover:bg-[#4F8EEA]"
      href="{{ amplifymeUrl }}"
      rel="nofollow"
    >
      BOOK YOUR FREE SIMULATION
    </a>
  </div>
</div>
