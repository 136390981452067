import { Component, Inject, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { MetricsService } from '../../service/MetricsService/MetricsService.service';
import { ActivatedRoute } from '@angular/router';
import { envStateKeyModel } from '../../app.config.server';
import { DecimalPipe } from '@angular/common';
import { WordSplitterPipe } from '../../pipes/word-splitter.pipe';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

const ENV_KEY = makeStateKey<envStateKeyModel>('env');

@Component({
    selector: 'app-preview-shared-event',
    imports: [DecimalPipe, WordSplitterPipe],
    templateUrl: './preview-shared-event.component.html',
    styleUrl: './preview-shared-event.component.css'
})
export class PreviewSharedEventComponent {
  metrics: any | null = null;
  uid: string | null = null;
  backgroundImage: string = "url('')";
  circleRadius = 140;

  constructor(
    private transferstate: TransferState,
    private metricsService: MetricsService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    combineLatest([
      this.route.queryParamMap,
      this.route.paramMap
    ]).pipe(
      map(([queryParamMap, paramMap]) => {
        return queryParamMap.keys.length > 0 ? queryParamMap : paramMap;
      })
    ).subscribe(params => {
      const uid = params.get('uid')?.replace(/\/$/, '');

      if (uid) {
        const env = this.transferstate.get<envStateKeyModel>(ENV_KEY, {SHARE_URL:"", AMPLIFYME_URL: "", PATHWAYS_URL: "", HUBSPOT_TRACKING_CODE: ""});
        this.metricsService.getMetrics(env.SHARE_URL, uid).subscribe(metrics => {
          this.metrics = metrics;
          if (this.metrics.event.sponsorBranding.length > 0) {
            this.backgroundImage = 'url(assets/metrics-bg-sponsored.jpg)';
          } else {
            this.backgroundImage = 'url(assets/metrics-bg-blank.jpg)';
          }
        });
      }
    });
  }

  calculateStrokeDasharray(): string {
    const circumference = this.circleRadius * 2 * Math.PI;
    return `${circumference} ${circumference}`;
  }

  calculateStrokeDashoffset(percent: number): number {
    const circumference = this.circleRadius * 2 * Math.PI;
    return circumference - percent / 100 * circumference;
  }
}
