import { Component, Inject, OnInit, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { CertificateService } from '../../service/CertificateService/CertificateService.service';
import { envStateKeyModel } from '../../app.config.server';
import { CommonModule } from '@angular/common';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

const ENV_KEY = makeStateKey<envStateKeyModel>('env');

@Component({
    selector: 'app-preview-certificate',
    imports: [
        CommonModule
    ],
    templateUrl: './preview-certificate.component.html',
    styleUrl: './preview-certificate.component.css'
})

export class PreviewCertificateComponent implements OnInit {
  certificate: any | null = null;
  uid: string | null = null;
  description: string | null = null;
  backgroundImageUrl: string = 'url(assets/UBS-MS-cert.jpg)'; // Default image

  constructor(
    private transferstate: TransferState,
    private certificateService: CertificateService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.route.queryParamMap,
      this.route.paramMap
    ]).pipe(
      map(([queryParamMap, paramMap]) => {
        return queryParamMap.keys.length > 0 ? queryParamMap : paramMap;
      })
    ).subscribe(params => {
      const uid = params.get('uid')?.replace(/\/$/, '');

      if (uid) {
        const env = this.transferstate.get<envStateKeyModel>(ENV_KEY, {SHARE_URL:"", AMPLIFYME_URL: "", PATHWAYS_URL: "", HUBSPOT_TRACKING_CODE: ""});
        this.certificateService.getCertificate(env.SHARE_URL, uid).subscribe(certificate => {
          this.certificate = certificate;
          switch (this.certificate.event.subtype) {
            case 'Finance Accelerator': {
              this.description = 'Finance Accelerator Simulation Experience';
              break;
            }
            case 'Mergers And Acquisition': {
              this.description = "Banking Accelerator Simulation Experience in Partnership with UBS";
              this.backgroundImageUrl = 'url(assets/background-with-logos-signature.jpg)';
              break;
            }
            case 'Sales & Trading': {
              this.description = 'Level 4 Diploma Investment Bank Sales & Trading';
              break;
            }
            case 'Global Macro': {
              this.description = 'Level 4 Diploma Asset Management';
              break;
            }
            case 'QuantTrading': {
              this.description = 'Level 4 Diploma Quantitative Finance';
              break;
            }
            default: {
              this.description = 'Simulation Experience';
              break;
            }
          }
        });
      }
    });
  }
}

