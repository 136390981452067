import { CertificateComponent } from './components/certificate/certificate.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PreviewCertificateComponent } from './components/preview-certificate/preview-certificate.component';
import { PreviewSharedEventComponent } from './components/preview-shared-event/preview-shared-event.component';
import { Routes } from '@angular/router';
import { SharedEventComponent } from './components/shared-event/shared-event.component';

export const routes: Routes = [
    { path: '', component: NotFoundComponent },
    { path: 'shared-events/:uid', component: SharedEventComponent },
    { path: 'certificate/:uid', component: CertificateComponent },
    { path: 'share-preview', component: PreviewSharedEventComponent},
    { path: 'certificate-preview', component: PreviewCertificateComponent},
    { path: '**', component: NotFoundComponent}
  ];