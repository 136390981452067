import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Inject, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';

import { Meta } from '@angular/platform-browser';
import { envStateKeyModel } from '../../app.config.server';
import { CertificateService } from '../../service/CertificateService/CertificateService.service';

const ENV_KEY = makeStateKey<envStateKeyModel>('env');

@Component({
    selector: 'app-certificate',
    imports: [],
    templateUrl: './certificate.component.html',
    styleUrl: './certificate.component.css'
})
export class CertificateComponent {
  amplifymeUrl = "";
  imageUrl = "";
  certificate: any | null = null;
  referralCode: string | null = "";

  constructor(
    private route: ActivatedRoute,
    private certificateService: CertificateService,
    private meta: Meta,
    private transferstate: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const uid = params.get('uid')?.replace(/\/$/, '');

      if (uid) {
        const env = this.transferstate.get<envStateKeyModel>(ENV_KEY, {SHARE_URL:"", AMPLIFYME_URL: "", PATHWAYS_URL: "", HUBSPOT_TRACKING_CODE: ""});

        this.certificateService.getCertificate(env.SHARE_URL, uid).subscribe(certificate => {
          this.certificate = certificate;
          switch (this.certificate.event.subtypeKey) {
            case 'MergersAndAcquisitions': {
              this.referralCode = "ASCBFA"
              this.meta.updateTag({ property: 'og:title', content: "Click here to book your own Free Banking Accelerator simulation, in partnership with UBS." });
              this.meta.updateTag({ property: 'og:description', content: "Click here to book your own Free Banking Accelerator simulation, in partnership with UBS." });
              break;
            }
            case 'TimedMultistepNotebook': {
              this.referralCode = "ASCQFA"
              this.meta.updateTag({ property: 'og:title', content: "Click here to book your own Free Quant Accelerator simulation." });
              this.meta.updateTag({ property: 'og:description', content: "Click here to book your own Free Quant Accelerator simulation." });
              break;
            }
            default: {
              this.referralCode = "ASCMFA"
              this.meta.updateTag({ property: 'og:title', content: "Click here to book your own Free Markets Accelerator simulation, in partnership with Morgan Stanley and UBS." });
              this.meta.updateTag({ property: 'og:description', content: "Click here to book your own Free Markets Accelerator simulation, in partnership with Morgan Stanley and UBS." });
              break;
            }
          }
        });
        // Construct the URL dynamically based on the env
        this.amplifymeUrl = `${env.PATHWAYS_URL}/pathways?r=${this.referralCode}`;
        this.imageUrl = `${env.SHARE_URL}/certificates/${uid}.png`;

        // Set dynamic meta tags with the constructed URL
        this.meta.updateTag({ property: 'og:image', content: this.imageUrl });

        // Get the current date and time in ISO format
        const currentTime = new Date().toISOString();
        this.meta.updateTag({ property: 'article:published_time', content: currentTime });
      }
    });
  }
}
