import { Component, Inject, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';

import { RouterOutlet } from '@angular/router';
import { envStateKeyModel } from './app.config.server';
import { isPlatformBrowser } from '@angular/common';

const ENV_KEY = makeStateKey<envStateKeyModel>('env');


@Component({
    selector: 'app-root',
    imports: [RouterOutlet],
    templateUrl: './app.component.html',
    styleUrl: './app.component.css'
})
export class AppComponent {
  constructor(
    private transferstate: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  title = 'share_service';
  hubspotTrackingCode = '';
  
  ngOnInit() {
    const env = this.transferstate.get<envStateKeyModel>(ENV_KEY, {
      SHARE_URL: "", AMPLIFYME_URL: "", PATHWAYS_URL: "",
      HUBSPOT_TRACKING_CODE: ''
    });

    this.hubspotTrackingCode = env.HUBSPOT_TRACKING_CODE

    if (isPlatformBrowser(this.platformId)) {
      this.loadScript();
    }
  }

  loadScript() {
    let node = document.createElement('script'); // creates the script tag
    node.src = `//js.hs-scripts.com/${this.hubspotTrackingCode}.js`; // sets the source (insert url in between quotes)
    node.type = 'text/javascript'; // set the script type
    node.async = true;
    node.defer = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
