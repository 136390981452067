@if (metrics){
<div class="metric-alignment">
  <div class="metric-body" [style.backgroundImage]="backgroundImage">
    <div class="metric-preview-widget-fa">
      <div class="metric-preview-description-fa metric-preview-title-fa">
        <span>{{ metrics.event.subtype }}</span>
      </div>

      <div class="metric-preview-container-fa">
        <div class="metric-preview-description-fa">
          <span
            >{{ metrics.player.firstName }} {{ metrics.player.lastName }}</span
          >
          <span class="metric-preview-divider-fa"> | </span>
          <span>{{ metrics.event.subtype }} Simulation</span>
        </div>
        <div class="metric-preview-description-fa">
          <span class="metric-preview-disclaimer"
            >No. Participants: {{ metrics.event.numberOfParticipants }}</span
          >
          <span class="metric-preview-divider-fa"> | </span>
          <span class="metric-preview-disclaimer"
            >Percentage / Performance in comparison to group</span
          >
        </div>
        @for (item of metrics.roles; track item.id){
        <svg class="metric-preview-progress-ring" width="350" height="350">
          <text
            class="metric-preview-value"
            text-anchor="middle"
            x="50%"
            y="48%"
          >
            {{ item.sessionScore.percent | number : "1.0-0" }}%
          </text>
          <text
            class="metric-preview-name"
            x="50%"
            y="55%"
            dominant-baseline="middle"
            text-anchor="middle"
          >
            {{ item.name | wordSplitter : 0 }}
          </text>
          <text
            class="metric-preview-name"
            x="50%"
            y="61%"
            dominant-baseline="middle"
            text-anchor="middle"
          >
            {{ item.name | wordSplitter : 1 }}
          </text>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="45%" stop-color="#CE443B" />
            <stop offset="75%" stop-color="#CE443B" />
            <stop offset="100%" stop-color="#CE443B" />
          </linearGradient>
          <circle
            class="metric-preview-empty-ring__circle"
            stroke="#333333"
            stroke-opacity="1"
            cx="50%"
            cy="50%"
            [style.r]="circleRadius"
          />
          <circle
            class="metric-preview-progress-ring__circle"
            stroke="url(#gradient)"
            cx="50%"
            cy="50%"
            [style.r]="circleRadius"
            [style.strokeDasharray]="calculateStrokeDasharray()"
            [style.strokeDashoffset]="
              calculateStrokeDashoffset(item.sessionScore.percent)
            "
          />
          <circle
            class="metric-preview-progress__circle"
            r="86"
            cx="50%"
            cy="50%"
          />
        </svg>
        }
      </div>
    </div>
  </div>
</div>

}
