import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wordSplitter',
  standalone: true
})
export class WordSplitterPipe implements PipeTransform {

  transform(value: string, index: number): string {
    if (!value || typeof value !== 'string') {
      return '';
    }
    
    const words = value.split(' ');
    if (index < 0 || index >= words.length) {
      return '';
    }
    
    return words[index];
  }

}
