@if (certificate){
<div class="certificate-alignment">
  <div class="certificate-wrapper" [style.backgroundImage]="backgroundImageUrl">
    <div class="certificate-body">
      @if (certificate.event.subtype == "Mergers And Acquisition"){
      <span class="certificate-completion">Certificate of Completion</span>
      }
      <div class="certificate-user-name-wrapper">
        <span class="certificate-user-name"
          >{{ certificate.user.firstName }}
          {{ certificate.user.lastName }}</span
        >
      </div>
      <span class="certificate-desc">{{ description }}</span>
      <span class="certificate-date">{{
        certificate.event.date | date : "MMMM yyyy"
      }}</span>
    </div>
  </div>
</div>
}
