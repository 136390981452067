

import { Component, OnInit, Inject, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { envStateKeyModel } from '../../app.config.server';

const ENV_KEY = makeStateKey<envStateKeyModel>('env');


@Component({
    selector: 'app-not-found',
    imports: [],
    templateUrl: './not-found.component.html',
    styleUrl: './not-found.component.css'
})

export class NotFoundComponent implements OnInit{
  constructor(
    private transferstate: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      const env = this.transferstate.get<envStateKeyModel>(ENV_KEY, {SHARE_URL:"", AMPLIFYME_URL: "", PATHWAYS_URL: "", HUBSPOT_TRACKING_CODE: ""});

      setTimeout(() => {
        window.location.href = env.AMPLIFYME_URL;
      }, 100);
    }
  }
}