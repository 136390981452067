import * as Sentry from "@sentry/angular";

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { bootstrapApplication } from '@angular/platform-browser';

if (typeof window !== 'undefined') {
  window.addEventListener('load', () => {
    Sentry.init({
      dsn: "https://cf93f96bb1ce5db5e7c354a12afdd4d9@o4508245627961344.ingest.de.sentry.io/4508245983559760",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
      ],
      environment: window.location.hostname,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  });
}


bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));